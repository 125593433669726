<template>
    <svg viewBox="0 0 354 346" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs>
            <linearGradient x1="0%" y1="99.88182%" x2="100%" y2="0.118179986%" :id="_uid">
                <stop stop-color="var(--color-image-main)" stop-opacity="0.401606206" offset="0%"></stop>
                <stop stop-color="var(--color-image-main)" stop-opacity="0.100442526" offset="100%"></stop>
            </linearGradient>
        </defs>
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g fill-rule="nonzero">
                <g transform="translate(9.048641, 0.938393)" :fill="`url(#${ _uid })`">
                    <ellipse cx="172.422836" cy="172.218946" rx="172.422836" ry="172.218946"></ellipse>
                </g>
                <path d="M175,154.028926 L80.9437721,189.350879 L89.9533686,151.250795 L65.4987494,145 C65.4987494,145 39.1630055,219.414227 57.4792183,221.398606 C89.4583358,224.87127 137.872542,212.171242 137.872542,212.171242 L175,154.028926 Z" fill="#FFE6D5"></path>
                <path d="M142,238.316513 L142.198072,312.591285 C166.659947,342.657239 252.028922,303.789082 252.028922,303.789082 L253.019281,273.426425 L255,201.030775 C253.019281,172.151636 226.180543,158.00877 226.180543,158.00877 C215.088519,152.470305 198.153374,149.404369 183.000876,150.096677 C167.056091,150.887886 163.19369,158.503276 156.657318,172.942845 C151.309378,184.712084 142,195.690112 142,238.316513 Z" fill="#FFFFFF"></path>
                <path d="M217.25491,225 C217.25491,225 229.133473,294.999495 212.404497,293.22486 C157.070191,287.605182 124.206166,286.816456 124.206166,286.816456 L115,305.745897 C114.802304,308.999394 219.729611,338.872418 236.557575,329.013334 C248.436138,322.013385 267.441839,303.182535 258.829881,246.985757 L256.751132,228.154907 L217.25491,225 Z" fill="#FFE6D5"></path>
                <path d="M247.431666,179.22065 C247.431666,179.22065 260.160493,191.507303 263,238 L213.65132,232.495579 C213.65132,232.495579 205.42654,153.271238 247.431666,179.22065 Z" fill="#FFFFFF"></path>
                <path d="M223.374337,81.2136494 L222.280133,96.0884799 C222.280133,96.0884799 235.01269,97.774294 231.332185,109.376662 C227.751154,120.979029 218.997521,115.921587 218.997521,115.921587 C218.997521,115.921587 216.809113,145.572082 186.370345,136.151356 C162.198381,128.713941 163.591005,97.3776318 166.674671,70.8012681 C167.868348,60.4880524 177.616712,53.3481338 187.862441,55.3314445 L206.165491,58.9014038 C216.809113,60.8847145 224.170122,70.5037715 223.374337,81.2136494 Z" fill="#FFE6D5"></path>
                <path d="M222,108 L213.831324,151.718057 C211.938581,161.554619 202.275635,167.81425 192.51307,165.528988 L192.51307,165.528988 C183.447832,163.343085 177.669988,154.599474 179.263876,145.458426 L183.746686,120.419902 L222,108 Z" fill="#FFE6D5"></path>
                <path d="M188,108.896057 C188,108.896057 188,108.996416 187.894226,109.197133 C187.788452,109.397849 187.576904,109.698925 187.153808,109.899642 C186.730713,110.100358 186.096069,110 185.567199,109.598566 C185.03833,109.297491 184.615234,108.594982 184.403686,107.892473 C183.874816,106.487455 183.98059,104.781362 184.086364,102.97491 C184.192138,101.168459 184.297912,99.1612903 184.403686,97.0537634 C184.721008,92.8387097 185.03833,89.125448 185.249877,86.4157706 C185.567199,83.7060932 185.778747,82 185.884521,82 C185.990295,82 186.096069,83.7060932 185.990295,86.4157706 C185.884521,89.125448 185.672973,92.9390681 185.461425,97.1541219 C185.355651,99.2616487 185.144104,101.168459 185.03833,103.075269 C184.932556,104.88172 184.721008,106.487455 185.03833,107.691756 C185.249877,108.996416 186.307617,109.598566 187.048035,109.498208 C187.682678,109.397849 187.894226,108.896057 188,108.896057 Z" fill="#333333"></path>
                <path d="M178.005369,132.007382 C178.105332,131.900482 179.504814,132.969478 182.003889,134.252273 C184.502964,135.535068 188.201595,136.817862 192.400042,136.817862 C196.598488,136.924762 200.397082,135.855766 202.896157,134.679871 C205.495195,133.503976 206.894677,132.541879 206.99464,132.648779 C207.094603,132.755679 205.795084,134.038473 203.196046,135.428168 C200.696971,136.817862 196.798414,138.100657 192.300079,137.993758 C187.901706,137.886858 184.003149,136.390264 181.604037,135.00057 C179.204925,133.503976 177.905406,132.114281 178.005369,132.007382 Z" fill="#333333"></path>
                <path d="M184.01172,114.129321 C184.10676,114.028605 184.772043,114.733611 186.007568,115.337902 C187.243092,115.942193 189.143899,116.445769 191.044707,115.740763 C192.945514,115.035757 194.276079,113.52503 194.941361,112.215733 C195.606644,110.906436 195.701684,110 195.891765,110 C195.986805,110 196.176886,111.007151 195.606644,112.517878 C195.036402,114.028605 193.705837,115.942193 191.424868,116.647199 C189.143899,117.45292 186.957971,116.747914 185.722446,115.841478 C184.391881,115.136472 183.91668,114.230036 184.01172,114.129321 Z" fill="#333333"></path>
                <path d="M222,110.816596 C222,110.613678 222.754917,110.613678 223.83337,110.106383 C224.264751,109.802006 224.803977,109.39617 224.911823,108.787416 C225.127513,108.178662 224.911823,107.468449 224.372596,107.36699 C223.941215,107.164071 223.401989,107.36699 223.294143,107.569908 L222.323536,107.062612 C223.509834,104.627596 224.588287,102.902793 224.803977,103.004252 C225.019668,103.105711 224.372596,104.931973 223.294143,107.468449 L222.323536,106.961153 C222.539226,106.555317 222.970608,106.352399 223.401989,106.25094 C223.83337,106.149481 224.264751,106.149481 224.696132,106.352399 C225.235359,106.555317 225.774585,107.062612 225.88243,107.569908 C226.098121,108.077203 225.990276,108.584498 225.774585,109.091793 C225.451049,110.004924 224.696132,110.512219 224.04906,110.715137 C222.754917,111.222432 222,110.918055 222,110.816596 Z" fill="#333333"></path>
                <path d="M199,81.5400233 C199,81.4555638 199.293406,81.2021855 199.880218,81.1177261 C200.467029,80.9488072 201.347247,80.9488072 202.325266,81.2021855 C203.303286,81.4555638 203.9879,81.7934016 204.476909,82.2156988 C204.965919,82.5535366 205.063721,82.8913744 204.965919,82.9758338 C204.770315,83.1447527 203.596692,82.3846177 202.03186,82.0467799 C200.467029,81.6244827 199.097802,81.7934016 199,81.5400233 Z" fill="#333333"></path>
                <path d="M173.004699,77.2074245 C172.905036,76.9721274 174.399982,75.7956422 176.59257,76.0309392 C178.785157,76.2662363 180.18044,77.6780186 179.981114,77.9133156 C179.781788,78.2662612 178.386505,77.4427215 176.492907,77.2074245 C174.599308,77.0897759 173.104363,77.56037 173.004699,77.2074245 Z" fill="#333333"></path>
                <path d="M221.388507,95.303553 C212.959408,87.0456815 210.480261,73.2162341 208.100281,61.5756201 C200.266177,67.3461809 189.457098,68.7390749 180.433004,65.1573475 C177.557194,68.5400901 174.284721,71.4253704 170.615584,73.8131887 C169.524759,74.5096357 168.334769,75.1065903 167.144778,74.808113 C165.756456,74.4101433 165.062295,72.718772 164.764798,71.325878 C163.673973,66.6497339 163.673973,61.6751126 165.260627,57.1979533 C166.847281,52.7207941 170.21892,48.741097 174.681384,47.0497258 C179.441346,45.2588621 184.796302,46.0548015 189.65543,47.2487106 C197.09287,49.1390667 204.232812,52.1238395 210.777759,56.0040442 C217.22354,59.7847564 223.47099,64.8588702 226.247634,71.8233402 C229.024278,78.7878101 229.024278,92.4182726 222.380165,96" fill="#333333"></path>
                <path d="M171.266907,153.31091 C179.41185,150.068371 117,173.257439 117,173.257439 L123.37857,216 L147.126475,208.237558 L171.266907,153.31091 Z" fill="#FFFFFF"></path>
                <path d="M130.554003,287.28535 C130.554003,287.28535 103.735191,278.78704 104.619327,281.553932 C105.503464,284.320823 110.120622,284.419641 114.639543,288.965248 C114.639543,288.965248 84.5788962,289.261701 86.0524573,291.73214 C87.5260184,294.202579 107.075263,294.795484 107.1735,295.289572 C107.271737,295.684842 85.7577451,296.277747 86.2489321,298.550551 C86.7401192,300.823355 106.682313,300.329267 106.682313,300.329267 C106.682313,300.329267 88.2136803,302.305618 88.5083925,304.776057 C88.9013421,307.246496 105.994651,305.46778 105.994651,305.46778 C105.994651,305.46778 91.750227,307.147678 92.0449392,309.321664 C92.241414,310.70511 133.894075,311.001563 133.992312,311.989738 C134.188787,312.582644 130.554003,287.28535 130.554003,287.28535" fill="#FFE6D5"></path>
                <path d="M91.7708529,144.143588 C90.4773351,141.853715 91.273346,139.165602 93.2633733,137.572647 C92.8653678,137.771766 92.5668638,137.970886 92.1688583,138.170005 C92.1688583,138.170005 101.322984,109.297688 98.4374441,109.895046 C95.651406,110.492404 88.8853133,129.010511 88.3878065,129.010511 C87.9898011,129.010511 94.1588856,108.202531 91.8703542,108.003411 C89.4823215,107.704732 83.8107439,127.118877 83.8107439,127.118877 C83.8107439,127.118877 87.7907984,108.799889 85.3032643,108.30209 C82.8157302,107.903852 79.1341798,124.829003 79.1341798,124.829003 C79.1341798,124.829003 82.0197193,110.591964 79.8306894,110.293285 C78.4376703,110.094165 66,145.338305 66,145.338305 C66.2985041,145.338305 88.785812,154 88.785812,154 C88.785812,154 90.4773351,151.013209 93.3628747,146.732141 C92.367861,146.234342 92.2683597,145.039626 91.7708529,144.143588 Z" fill="#FFE6D5"></path>
                <g transform="translate(127.000000, 165.000000)" fill="var(--color-image-main)">
                    <path d="M112.502384,52 C112.597785,52 112.597785,52.2891105 112.597785,52.8673314 C112.597785,53.4455523 112.502384,54.2165135 112.693187,55.0838448 C112.788589,55.9511762 113.265597,57.0112478 114.124212,57.5894687 C114.60122,57.8785792 115.078228,58.0713195 115.650638,57.8785792 C116.223048,57.782209 116.700056,57.3967284 117.081663,56.8185075 C117.558671,56.3366568 118.03568,55.7584359 118.608089,55.2765851 C119.180499,54.7947344 119.943713,54.5056239 120.706926,54.5056239 C122.13795,54.3128836 123.378172,54.9874747 124.045983,55.854806 C124.713795,56.6257672 124.904598,57.4930986 125,58.0713195 C125,58.6495404 124.904598,58.9386508 124.809197,58.9386508 C124.713795,58.9386508 124.713795,58.6495404 124.618393,58.1676896 C124.522992,57.6858389 124.236787,57.0112478 123.568975,56.3366568 C122.996565,55.7584359 121.947147,55.2765851 120.802327,55.4693254 C120.229917,55.5656956 119.657508,55.7584359 119.275901,56.1439165 C118.798893,56.5293971 118.417286,57.107618 117.844876,57.5894687 C117.367868,58.1676896 116.700056,58.7459105 115.841442,58.9386508 C114.982827,59.1313911 114.219613,58.8422807 113.647204,58.4568001 C112.406982,57.5894687 112.025376,56.2402866 112.025376,55.2765851 C111.929974,54.2165135 112.120777,53.4455523 112.31158,52.9637015 C112.31158,52.2891105 112.406982,52 112.502384,52 Z"></path>
                    <path d="M98,38.7350736 C98,38.8327128 97.6953125,38.6374343 97.1875,38.3445166 C96.6796875,38.0515988 96.0703125,37.5634026 95.15625,37.2704848 C94.2421875,36.8799278 93.125,36.6846493 92.109375,37.2704848 C91.6015625,37.4657633 91.1953125,37.8563203 90.9921875,38.4421558 C90.7890625,38.9303521 90.890625,39.6138268 91.09375,40.2973016 C91.296875,40.9807764 91.6015625,41.6642511 91.703125,42.4453652 C91.8046875,43.2264792 91.6015625,44.0075932 91.296875,44.691068 C90.6875,45.9603782 89.3671875,46.7414923 88.25,46.9367708 C87.1328125,47.1320493 86.21875,46.8391315 85.7109375,46.5462138 C85.203125,46.253296 85,45.9603782 85,45.9603782 C85,45.862739 85.3046875,46.0580175 85.8125,46.1556568 C86.3203125,46.253296 87.1328125,46.4485745 88.046875,46.1556568 C88.9609375,45.9603782 89.875,45.2769035 90.3828125,44.2028717 C90.5859375,43.7146754 90.6875,43.1288399 90.6875,42.4453652 C90.5859375,41.8595297 90.3828125,41.1760549 90.1796875,40.3949409 C89.9765625,39.7114661 89.7734375,38.8327128 90.078125,37.9539596 C90.3828125,37.0752063 91.09375,36.58701 91.8046875,36.2940923 C93.2265625,35.7082568 94.6484375,36.0988138 95.5625,36.58701 C96.4765625,37.0752063 97.0859375,37.6610418 97.4921875,38.0515988 C97.796875,38.4421558 98,38.6374343 98,38.7350736 Z"></path>
                    <path d="M99,6.73507358 C99,6.83271283 98.6953125,6.63743433 98.1875,6.34451657 C97.6796875,6.05159881 97.0703125,5.56340255 96.15625,5.27048479 C95.2421875,4.87992778 94.125,4.68464928 93.109375,5.27048479 C92.6015625,5.4657633 92.1953125,5.85632031 91.9921875,6.44215582 C91.7890625,6.93035208 91.890625,7.61382685 92.09375,8.29730162 C92.296875,8.98077638 92.6015625,9.66425115 92.703125,10.4453652 C92.8046875,11.2264792 92.6015625,12.0075932 92.296875,12.691068 C91.6875,13.9603782 90.3671875,14.7414923 89.25,14.9367708 C88.1328125,15.1320493 87.21875,14.8391315 86.7109375,14.5462138 C86.203125,14.253296 86,13.9603782 86,13.9603782 C86,13.862739 86.3046875,14.0580175 86.8125,14.1556568 C87.3203125,14.253296 88.1328125,14.4485745 89.046875,14.1556568 C89.9609375,13.9603782 90.875,13.2769035 91.3828125,12.2028717 C91.5859375,11.7146754 91.6875,11.1288399 91.6875,10.4453652 C91.5859375,9.85952965 91.3828125,9.17605489 91.1796875,8.39494087 C90.9765625,7.7114661 90.7734375,6.83271283 91.078125,5.95395956 C91.3828125,5.07520629 92.09375,4.58701003 92.8046875,4.29409227 C94.2265625,3.70825676 95.6484375,4.09881377 96.5625,4.58701003 C97.4765625,5.07520629 98.0859375,5.6610418 98.4921875,6.05159881 C98.796875,6.44215582 99,6.63743433 99,6.73507358 Z"></path>
                    <path d="M67.6091196,47.9751856 C67.5065109,47.8778109 67.7117284,47.6830613 68.1221635,47.1961876 C68.4299898,46.8066886 69.0456425,46.1250653 69.4560776,45.3460673 C69.8665127,44.5670693 70.1743391,43.495947 69.7639039,42.4248248 C69.5586864,41.937951 69.1482513,41.4510773 68.6352074,41.2563278 C68.1221635,41.0615783 67.4039021,41.0615783 66.6856406,41.2563278 C65.9673792,41.3537025 65.146509,41.548452 64.3256388,41.6458268 C63.5047685,41.6458268 62.6838983,41.4510773 62.0682457,41.0615783 C60.7343316,40.2825803 60.0160701,39.0167085 60.0160701,37.9455862 C59.9134614,36.874464 60.3238965,36.095466 60.6317228,35.6085922 C60.9395491,35.1217184 61.2473755,34.9269689 61.3499842,35.0243437 C61.452593,35.1217184 61.2473755,35.3164679 61.0421579,35.8033417 C60.8369403,36.2902155 60.6317228,37.0692135 60.8369403,37.9455862 C60.9395491,38.821959 61.5552018,39.7957065 62.6838983,40.2825803 C63.1969422,40.5747045 63.8125949,40.6720793 64.4282475,40.6720793 C65.0439002,40.6720793 65.7621616,40.4773298 66.5830319,40.2825803 C67.4039021,40.1852055 68.3273811,40.0878308 69.1482513,40.379955 C69.9691215,40.769454 70.4821654,41.4510773 70.7899917,42.1327005 C71.3030356,43.5933218 70.7899917,44.9565683 70.1743391,45.7355663 C69.5586864,46.6119391 68.9430337,47.0988128 68.5325986,47.4883118 C67.916946,47.8778109 67.7117284,48.0725604 67.6091196,47.9751856 Z"></path>
                    <path d="M46,81.8411227 C46,81.7453039 46.2970503,81.9369415 46.7921341,82.1285791 C47.2872179,82.4160356 47.9803352,82.7993108 48.8714861,83.0867673 C49.762637,83.3742237 50.8518213,83.4700425 51.841989,82.8951296 C52.3370728,82.6076732 52.7331398,82.224398 52.8321566,81.6494851 C52.9311734,81.170391 52.8321566,80.4996593 52.5351063,79.8289276 C52.3370728,79.1581959 51.9410057,78.4874642 51.841989,77.7209137 C51.7429722,76.9543632 51.841989,76.1878127 52.0400225,75.517081 C52.5351063,74.1756176 53.7233074,73.3132483 54.8124918,73.1216107 C55.9016762,72.8341543 56.7928271,73.1216107 57.2879109,73.3132483 C57.7829947,73.5048859 58.080045,73.7923424 57.9810282,73.8881612 C57.9810282,73.98398 57.6839779,73.7923424 57.1888941,73.6965236 C56.6938103,73.6007048 55.9016762,73.5048859 55.0105254,73.7923424 C54.1193745,74.0797988 53.3272404,74.7505305 52.9311734,75.8045375 C52.7331398,76.2836315 52.6341231,76.9543632 52.8321566,77.5292761 C52.9311734,78.104189 53.2282236,78.7749207 53.5252739,79.4456524 C53.8223242,80.1163841 54.0203577,80.9787534 53.8223242,81.8411227 C53.6242907,82.703492 52.9311734,83.2784049 52.3370728,83.5658613 C50.9508381,84.236593 49.5646034,84.0449554 48.6734526,83.5658613 C47.683285,83.1825861 47.0891844,82.6076732 46.6931173,82.3202168 C46.1980335,82.1285791 46,81.8411227 46,81.8411227 Z"></path>
                    <path d="M21.0242647,28.8411227 C21.0242647,28.7453039 21.3207144,28.9369415 21.8147971,29.1285791 C22.3088798,29.4160356 23.0005956,29.7993108 23.8899445,30.0867673 C24.7792934,30.3742237 25.8662754,30.4700425 26.8544408,29.8951296 C27.3485236,29.6076732 27.7437897,29.224398 27.8426063,28.6494851 C27.9414228,28.170391 27.8426063,27.4996593 27.5461566,26.8289276 C27.3485236,26.1581959 26.9532574,25.4874642 26.8544408,24.7209137 C26.7556243,23.9543632 26.8544408,23.1878127 27.0520739,22.517081 C27.5461566,21.1756176 28.7319552,20.3132483 29.8189372,20.1216107 C30.9059192,19.8341543 31.795268,20.1216107 32.2893508,20.3132483 C32.7834335,20.5048859 33.0798831,20.7923424 32.9810666,20.8881612 C32.9810666,20.98398 32.6846169,20.7923424 32.1905342,20.6965236 C31.6964515,20.6007048 30.9059192,20.5048859 30.0165703,20.7923424 C29.1272214,21.0797988 28.336689,21.7505305 27.9414228,22.8045375 C27.7437897,23.2836315 27.6449732,23.9543632 27.8426063,24.5292761 C27.9414228,25.104189 28.2378725,25.7749207 28.5343221,26.4456524 C28.8307717,27.1163841 29.0284048,27.9787534 28.8307717,28.8411227 C28.6331386,29.703492 27.9414228,30.2784049 27.3485236,30.5658613 C25.9650919,31.236593 24.5816603,31.0449554 23.6923114,30.5658613 C22.704146,30.1825861 22.1112467,29.6076732 21.7159805,29.3202168 C21.1230813,29.1285791 20.9254482,28.9369415 21.0242647,28.8411227 Z"></path>
                    <path d="M3.93800688,21.0337972 C3.93800688,21.1307847 3.7475204,21.3247597 3.36654745,21.7127098 C2.9855745,22.1006599 2.41411507,22.5855975 1.84265564,23.2645101 C1.27119621,23.9434228 0.794980018,25.0102855 0.985466495,26.0771482 C1.08070973,26.6590733 1.36643945,27.1440109 1.7474124,27.4349735 C2.22362859,27.725936 2.79508802,27.8229235 3.46179069,27.8229235 C4.12849336,27.8229235 4.89043926,27.725936 5.65238517,27.9199111 C6.41433107,28.0168986 7.08103374,28.4048487 7.65249317,28.8897863 C8.70016879,29.8596615 9.08114174,31.2174867 8.9858985,32.2843494 C8.89065527,33.3512122 8.41443908,34.1271123 8.03346612,34.5150624 C7.65249317,34.9030125 7.36676345,35 7.27152022,35 C7.17627698,34.9030125 7.46200669,34.7090374 7.65249317,34.3210874 C7.93822288,33.9331373 8.2239526,33.1572371 8.2239526,32.2843494 C8.2239526,31.4114618 7.84297965,30.3445991 6.9857905,29.6656864 C6.50957431,29.2777363 6.03335812,29.0837613 5.46189869,28.9867738 C4.89043926,28.8897863 4.22373659,28.9867738 3.46179069,28.9867738 C2.69984478,28.9867738 1.84265564,28.8897863 1.17595297,28.4048487 C0.509250304,27.9199111 0.128277351,27.1440109 0.0330341125,26.3681107 C-0.157452364,24.913298 0.509250304,23.6524602 1.17595297,22.9735476 C1.84265564,22.1976474 2.60460154,21.8096973 2.9855745,21.5187348 C3.65227716,21.0337972 3.93800688,20.9368096 3.93800688,21.0337972 Z"></path>
                    <path d="M73.0242647,108.841123 C73.0242647,108.745304 73.3207144,108.936942 73.8147971,109.128579 C74.3088798,109.416036 75.0005956,109.799311 75.8899445,110.086767 C76.7792934,110.374224 77.8662754,110.470043 78.8544408,109.89513 C79.3485236,109.607673 79.7437897,109.224398 79.8426063,108.649485 C79.9414228,108.170391 79.8426063,107.499659 79.5461566,106.828928 C79.3485236,106.158196 78.9532574,105.487464 78.8544408,104.720914 C78.7556243,103.954363 78.8544408,103.187813 79.0520739,102.517081 C79.5461566,101.175618 80.7319552,100.313248 81.8189372,100.121611 C82.9059192,99.8341543 83.795268,100.121611 84.2893508,100.313248 C84.7834335,100.504886 85.0798831,100.792342 84.9810666,100.888161 C84.9810666,100.98398 84.6846169,100.792342 84.1905342,100.696524 C83.6964515,100.600705 82.9059192,100.504886 82.0165703,100.792342 C81.1272214,101.079799 80.336689,101.750531 79.9414228,102.804537 C79.7437897,103.283632 79.6449732,103.954363 79.8426063,104.529276 C79.9414228,105.104189 80.2378725,105.774921 80.5343221,106.445652 C80.8307717,107.116384 81.0284048,107.978753 80.8307717,108.841123 C80.6331386,109.703492 79.9414228,110.278405 79.3485236,110.565861 C77.9650919,111.236593 76.5816603,111.044955 75.6923114,110.565861 C74.704146,110.182586 74.1112467,109.607673 73.7159805,109.320217 C73.1230813,109.128579 72.9254482,108.841123 73.0242647,108.841123 Z"></path>
                    <path d="M35.3802008,58 C35.2825353,58 35.4778664,57.712 35.7708631,57.232 C36.0638599,56.752 36.4545222,56.176 36.8451845,55.312 C37.1381812,54.448 37.3335124,53.392 36.8451845,52.432 C36.5521878,51.952 36.1615255,51.568 35.6731976,51.376 C35.1848697,51.184 34.5012106,51.28 33.8175516,51.568 C33.1338926,51.76 32.4502335,52.048 31.6689089,52.144 C30.8875843,52.24 30.1062597,52.144 29.4226006,51.856 C28.0552826,51.28 27.2739579,50.128 27.0786268,49.072 C26.8832956,48.016 27.0786268,47.152 27.3716235,46.672 C27.6646203,46.192 27.8599514,46 27.957617,46 C28.0552826,46 27.8599514,46.288 27.7622858,46.768 C27.6646203,47.248 27.5669547,48.016 27.7622858,48.88 C27.957617,49.744 28.641276,50.608 29.7155974,50.992 C30.2039253,51.184 30.8875843,51.28 31.4735778,51.184 C32.0595712,51.088 32.7432303,50.8 33.4268893,50.608 C34.1105483,50.416 34.9895385,50.224 35.8685287,50.416 C36.7475189,50.704 37.3335124,51.28 37.6265091,51.952 C38.3101681,53.296 37.9195058,54.64 37.5288435,55.504 C37.0405156,56.368 36.5521878,57.04 36.1615255,57.424 C35.6731976,57.904 35.4778664,58 35.3802008,58 Z"></path>
                    <path d="M29.3802008,85 C29.2825353,85 29.4778664,84.712 29.7708631,84.232 C30.0638599,83.752 30.4545222,83.176 30.8451845,82.312 C31.1381812,81.448 31.3335124,80.392 30.8451845,79.432 C30.5521878,78.952 30.1615255,78.568 29.6731976,78.376 C29.1848697,78.184 28.5012106,78.28 27.8175516,78.568 C27.1338926,78.76 26.4502335,79.048 25.6689089,79.144 C24.8875843,79.24 24.1062597,79.144 23.4226006,78.856 C22.0552826,78.28 21.2739579,77.128 21.0786268,76.072 C20.8832956,75.016 21.0786268,74.152 21.3716235,73.672 C21.6646203,73.192 21.8599514,73 21.957617,73 C22.0552826,73 21.8599514,73.288 21.7622858,73.768 C21.6646203,74.248 21.5669547,75.016 21.7622858,75.88 C21.957617,76.744 22.641276,77.608 23.7155974,77.992 C24.2039253,78.184 24.8875843,78.28 25.4735778,78.184 C26.0595712,78.088 26.7432303,77.8 27.4268893,77.608 C28.1105483,77.416 28.9895385,77.224 29.8685287,77.416 C30.7475189,77.704 31.3335124,78.28 31.6265091,78.952 C32.3101681,80.296 31.9195058,81.64 31.5288435,82.504 C31.0405156,83.368 30.5521878,84.04 30.1615255,84.424 C29.6731976,84.808 29.4778664,85 29.3802008,85 Z"></path>
                    <path d="M82.9022037,74 C82.8044074,74 82.7066111,73.7126357 82.6088148,73.2336952 C82.5110185,72.7547547 82.4132222,71.9884498 82.022037,71.1263569 C81.7286481,70.264264 81.044074,69.4021711 79.9683147,69.0190187 C79.4793332,68.8274425 78.8925554,68.8274425 78.4035739,69.0190187 C77.9145924,69.2105949 77.5234072,69.7853235 77.132222,70.3600521 C76.7410368,70.9347807 76.4476479,71.6052974 75.9586664,72.1800261 C75.4696849,72.7547547 74.7851108,73.2336952 74.1005367,73.4252714 C72.7313885,73.9042119 71.264444,73.5210595 70.4820736,72.8505428 C69.6019069,72.1800261 69.2107217,71.4137212 69.1129254,70.9347807 C68.9173328,70.3600521 69.0151291,70.0726878 69.1129254,70.0726878 C69.2107217,70.0726878 69.308518,70.3600521 69.5041106,70.8389926 C69.6997032,71.3179331 70.1886847,71.8926617 70.9710551,72.3716023 C71.7534255,72.8505428 72.8291848,73.042119 73.9049441,72.6589666 C74.3939256,72.4673904 74.9807034,72.1800261 75.2740923,71.7010855 C75.6652775,71.222145 75.9586664,70.6474164 76.3498516,69.9768997 C76.7410368,69.306383 77.2300183,68.6358663 78.0123887,68.2527138 C78.7947591,67.8695614 79.6749258,67.9653495 80.3594999,68.2527138 C81.7286481,68.8274425 82.5110185,70.0726878 82.7066111,71.0305688 C83,71.9884498 83,72.8505428 83,73.3294833 C83,73.7126357 83,74 82.9022037,74 Z"></path>
                    <path d="M33.9022037,107 C33.8044074,107 33.7066111,106.712636 33.6088148,106.233695 C33.5110185,105.754755 33.4132222,104.98845 33.022037,104.126357 C32.7286481,103.264264 32.044074,102.402171 30.9683147,102.019019 C30.4793332,101.827442 29.8925554,101.827442 29.4035739,102.019019 C28.9145924,102.210595 28.5234072,102.785323 28.132222,103.360052 C27.7410368,103.934781 27.4476479,104.605297 26.9586664,105.180026 C26.4696849,105.754755 25.7851108,106.233695 25.1005367,106.425271 C23.7313885,106.904212 22.264444,106.521059 21.4820736,105.850543 C20.6019069,105.180026 20.2107217,104.413721 20.1129254,103.934781 C19.9173328,103.360052 20.0151291,103.072688 20.1129254,103.072688 C20.2107217,103.072688 20.308518,103.360052 20.5041106,103.838993 C20.6997032,104.317933 21.1886847,104.892662 21.9710551,105.371602 C22.7534255,105.850543 23.8291848,106.042119 24.9049441,105.658967 C25.3939256,105.46739 25.9807034,105.180026 26.2740923,104.701086 C26.6652775,104.222145 26.9586664,103.647416 27.3498516,102.9769 C27.7410368,102.306383 28.2300183,101.635866 29.0123887,101.252714 C29.7947591,100.869561 30.6749258,100.96535 31.3594999,101.252714 C32.7286481,101.827442 33.5110185,103.072688 33.7066111,104.030569 C34,104.98845 34,105.850543 34,106.329483 C34,106.712636 33.9022037,107 33.9022037,107 Z"></path>
                    <path d="M35.9022037,6 C35.8044074,6 35.7066111,5.71263569 35.6088148,5.23369518 C35.5110185,4.75475467 35.4132222,3.98844985 35.022037,3.12635692 C34.7286481,2.264264 34.044074,1.40217108 32.9683147,1.01901867 C32.4793332,0.827442462 31.8925554,0.827442462 31.4035739,1.01901867 C30.9145924,1.21059487 30.5234072,1.78532349 30.132222,2.3600521 C29.7410368,2.93478072 29.4476479,3.60529744 28.9586664,4.18002605 C28.4696849,4.75475467 27.7851108,5.23369518 27.1005367,5.42527138 C25.7313885,5.9042119 24.264444,5.52105949 23.4820736,4.85054277 C22.6019069,4.18002605 22.2107217,3.41372123 22.1129254,2.93478072 C21.9173328,2.3600521 22.0151291,2.0726878 22.1129254,2.0726878 C22.2107217,2.0726878 22.308518,2.3600521 22.5041106,2.83899262 C22.6997032,3.31793313 23.1886847,3.89266174 23.9710551,4.37160226 C24.7534255,4.85054277 25.8291848,5.04211897 26.9049441,4.65896656 C27.3939256,4.46739036 27.9807034,4.18002605 28.2740923,3.70108554 C28.6652775,3.22214503 28.9586664,2.64741641 29.3498516,1.97689969 C29.7410368,1.30638297 30.2300183,0.635866257 31.0123887,0.252713847 C31.7947591,-0.130438564 32.6749258,-0.034650461 33.3594999,0.252713847 C34.7286481,0.827442462 35.5110185,2.0726878 35.7066111,3.03056882 C36,3.98844985 36,4.85054277 36,5.32948328 C36,5.71263569 36,6 35.9022037,6 Z"></path>
                    <path d="M51.8440056,120.963286 C51.8440056,120.861495 52.0329145,120.657913 52.5051865,120.35254 C52.9774586,120.047166 53.5441851,119.640002 54.2053661,118.927465 C54.866547,118.316718 55.4332735,117.298808 55.3388191,116.077315 C55.3388191,115.466569 55.1499102,114.957613 54.6776382,114.550449 C54.2998205,114.143285 53.6386396,114.041494 52.9774586,113.939703 C52.3162777,113.837912 51.5606424,113.837912 50.805007,113.532539 C50.0493717,113.328956 49.3881908,112.820001 49.0103731,112.209255 C48.0658289,111.089553 47.8769201,109.562687 48.0658289,108.544777 C48.3491922,107.425075 48.9159187,106.712537 49.2937363,106.407164 C49.7660084,106 50.0493717,106 50.0493717,106 C50.0493717,106.101791 49.8604629,106.305373 49.5770996,106.712537 C49.2937363,107.119702 48.9159187,107.832239 48.7270098,108.748359 C48.6325554,109.664478 48.8214643,110.78418 49.5770996,111.598508 C49.9549173,112.005673 50.4271894,112.412837 50.9939159,112.514628 C51.5606424,112.71821 52.2218233,112.71821 52.9774586,112.820001 C53.733094,112.921792 54.5831837,113.125374 55.1499102,113.736121 C55.8110912,114.346867 56,115.262987 56,115.975524 C56,117.50239 55.1499102,118.723883 54.3942749,119.334629 C53.6386396,120.047166 52.8830042,120.35254 52.4107321,120.556122 C52.2218233,120.963286 51.93846,121.065077 51.8440056,120.963286 Z"></path>
                    <path d="M109.484968,21.0257521 C109.586395,21.0257521 109.383541,21.3347767 109.180686,21.8498178 C108.977832,22.364859 108.572123,23.0859165 108.267841,24.0129905 C107.963559,24.9400646 107.963559,26.073155 108.572123,27.1032373 C108.876404,27.6182784 109.383541,27.927303 109.890677,28.0303113 C110.49924,28.1333195 111.107804,27.927303 111.716368,27.6182784 C112.426358,27.3092537 113.136349,27.000229 113.84634,26.7942126 C114.657758,26.5881961 115.469176,26.6912044 116.179167,26.8972208 C117.599149,27.4122619 118.613421,28.5453524 118.816276,29.6784428 C119.120558,30.8115333 119.01913,31.7386073 118.714849,32.2536484 C118.511994,32.8716978 118.207712,33.0777142 118.207712,32.974706 C118.106285,32.974706 118.207712,32.6656813 118.30914,32.047632 C118.410567,31.5325909 118.410567,30.7085251 118.106285,29.8844593 C117.802003,29.0603935 116.990585,28.1333195 115.874885,27.8242948 C115.266322,27.6182784 114.657758,27.6182784 114.049195,27.8242948 C113.440631,28.0303113 112.73064,28.3393359 112.020649,28.6483606 C111.310659,28.9573853 110.397813,29.2664099 109.484968,29.0603935 C108.572123,28.8543771 107.862132,28.2363277 107.55785,27.6182784 C106.746432,26.2791715 106.949286,24.7340481 107.354995,23.8069741 C107.760704,22.7768919 108.267841,22.0558343 108.572123,21.6438014 C109.180686,21.1287603 109.383541,20.9227438 109.484968,21.0257521 Z"></path>
                    <path d="M53.4849679,11 C53.5863951,11 53.3835406,11.2838818 53.1806861,11.7570182 C52.9778316,12.2301546 52.5721225,12.8925455 52.2678408,13.744191 C51.963559,14.5958365 51.963559,15.6367365 52.5721225,16.5830093 C52.8764043,17.0561456 53.3835406,17.3400275 53.8906769,17.4346547 C54.4992405,17.529282 55.107804,17.3400275 55.7163676,17.0561456 C56.4263584,16.7722638 57.1363493,16.488382 57.8463401,16.2991274 C58.6577582,16.1098729 59.4691763,16.2045002 60.1791671,16.3937547 C61.5991487,16.8668911 62.6134213,17.9077911 62.8162758,18.9486912 C63.1205576,19.9895912 63.0191304,20.8412367 62.7148486,21.314373 C62.5119941,21.8821367 62.2077123,22.0713912 62.2077123,21.976764 C62.106285,21.976764 62.2077123,21.6928821 62.3091395,21.1251185 C62.4105668,20.6519821 62.4105668,19.8949639 62.106285,19.1379457 C61.8020032,18.3809275 60.9905852,17.529282 59.8748853,17.2454002 C59.2663217,17.0561456 58.6577582,17.0561456 58.0491946,17.2454002 C57.440631,17.4346547 56.7306402,17.7185366 56.0206494,18.0024184 C55.3106586,18.2863002 54.3978132,18.570182 53.4849679,18.3809275 C52.5721225,18.1916729 51.8621317,17.6239093 51.5578499,17.0561456 C50.7464318,15.8259911 50.9492864,14.4065819 51.3549954,13.5549364 C51.7607045,12.6086637 52.2678408,11.9462728 52.5721225,11.5677637 C53.1806861,11.1892546 53.3835406,11 53.4849679,11 Z"></path>
                </g>
                <path d="M215.524728,235 C215.426152,235 215.229001,233.815537 214.933273,231.644023 C214.637546,229.472508 214.243243,226.313941 213.750364,222.464438 C212.764607,214.666726 211.581698,203.907857 210.300213,192.063231 C210.004486,189.102075 209.807334,186.140918 210.300213,183.278467 C210.694516,180.416016 211.877425,177.750975 213.553213,175.678166 C215.229001,173.605356 217.200516,172.026073 219.270607,170.84161 C221.340697,169.657147 223.410788,169.064916 225.382303,168.57139 C229.325334,167.781748 232.479758,167.979159 234.648424,168.176569 C235.732758,168.275274 236.619939,168.472685 237.112818,168.57139 C237.704273,168.670095 238,168.768801 238,168.768801 C238,168.867506 236.817091,168.670095 234.648424,168.57139 C232.479758,168.472685 229.325334,168.37398 225.480879,169.262327 C223.60794,169.755853 221.636425,170.446789 219.66491,171.532547 C217.693395,172.618304 215.820455,174.197587 214.243243,176.171692 C212.666031,178.145796 211.581698,180.613426 211.187395,183.377172 C210.793092,186.140918 210.891667,189.00337 211.187395,191.964526 C212.46888,203.809152 213.553213,214.56802 214.341819,222.365732 C214.736122,226.313941 215.031849,229.373803 215.229001,231.644023 C215.524728,233.815537 215.524728,235 215.524728,235 Z" fill="#333333"></path>
                <path d="M213,233.048327 C213,232.775919 224.487379,233.683946 238.549515,235.045987 C252.61165,236.408027 264,237.770067 264,237.951673 C264,238.224081 252.512621,237.316054 238.450485,235.954013 C224.38835,234.591973 213,233.229933 213,233.048327 Z" fill="#333333"></path>
                <path d="M142.272727,233 C142.181818,233 142,230.91689 142,227.544236 C142,224.171582 142.181818,219.509383 142.545455,214.450402 C142.909091,209.391421 143.181818,204.729223 143.363636,201.455764 C143.545455,198.08311 143.636364,196 143.727273,196 C143.818182,196 144,198.08311 144,201.455764 C144,204.828418 143.818182,209.490617 143.454545,214.549598 C143.090909,219.608579 142.818182,224.270777 142.636364,227.544236 C142.454545,230.91689 142.454545,233 142.272727,233 Z" fill="#333333"></path>
                <polygon fill="#333333" points="89 155.774194 64.4772727 149 63 153.451613 87.8181818 161"></polygon>
                <path d="M73,155 C73,156.645161 74.3548387,158 76,158 C77.6451613,158 79,156.645161 79,155 C79,153.354839 77.6451613,152 76,152 C74.3548387,152 73,153.354839 73,155 Z" fill="#999999"></path>
                <path d="M174.251677,88 C174.003354,88 173.92058,87.047619 174.086128,85.9047619 C174.251677,84.7619048 174.582774,84 174.748323,84 C174.996646,84 175.07942,84.952381 174.913872,86.0952381 C174.748323,87.2380952 174.5,88 174.251677,88 Z" fill="#333333"></path>
                <path d="M196.155172,91 C195.948276,91 195.948276,89.9288157 196.155172,88.8576314 C196.362069,87.7864471 196.637931,86.9100236 196.844828,87.007404 C197.051724,87.007404 197.051724,88.0785883 196.844828,89.1497726 C196.637931,90.2209569 196.362069,91 196.155172,91 Z" fill="#333333"></path>
                <g transform="translate(27.053720, 55.600874)" fill="var(--color-image-main)">
                    <path d="M2.61153092,25.5297939 C7.44846786,9.39608623 17.4184807,-1.98659098 24.7232426,0.289944458 C25.4161487,0.486798467 26.061768,0.796092828 26.658994,1.20815985 L26.6975026,1.08178287 L60.112013,26.9586741 C61.5067322,25.3169734 62.8338791,24.5125337 63.9123031,24.8369353 C64.0017841,24.8647803 64.0883716,24.9004587 64.1720657,24.9437648 L83.4574769,30.7757234 C84.7766331,31.1862233 85.4669447,33.2991929 85.5284116,36.456024 L88.5905528,37.4073701 C89.8738218,37.8032894 90.6635258,38.991047 90.4660998,40.3767642 C89.8738218,44.1379967 88.1957008,52.4523 83.4574769,63.439058 C82.9639119,64.6268157 81.5819299,65.3196743 80.2986609,64.9237551 L77.3226344,63.9811715 C75.5298973,66.613483 73.774752,68.0092624 72.401621,67.5962097 L70.662,67.07 L58.7792272,89.4707459 C58.2856622,90.4605439 57.1011062,90.9554429 56.0152632,90.6585035 C53.7448643,90.0646247 50.0924833,88.5799277 49.2040663,85.6105336 C48.2421804,82.3461204 54.2683546,69.3498356 57.3470859,63.0449787 L52.7577343,61.6574216 L52.7661751,61.6263093 C51.9444351,61.3150512 51.3778082,60.3192885 51.0662943,58.8092024 L10.1743313,59.0765525 C9.17020082,59.2196848 8.1906801,59.1621427 7.25104186,58.8859871 C-0.15243304,56.7084315 -2.22540601,41.7624814 2.61153092,25.5297939 Z"></path>
                </g>
                <path d="M111.498327,91.7215065 L115.644273,93.0082439 C116.927542,93.4041631 117.717246,94.5919207 117.51982,95.977638 C116.927542,99.7388704 115.249421,108.053174 110.511197,119.039932 C110.017632,120.227689 108.63565,120.920548 107.352381,120.524629 L103.601287,119.336871 L111.498327,91.7215065 Z" fill="#000000" opacity="0.347966"></path>
                <g transform="translate(75.811454, 80.437809)" fill="var(--color-image-main)">
                    <path d="M15.0558559,0 L34.6997426,5.93878811 C37.5624196,6.82960633 37.4637066,15.7377885 34.4036036,25.9327081 C31.3435006,36.1276277 26.6052767,43.6500926 23.6438867,42.7592744 L4,36.8204863 L15.0558559,0 Z"></path>
                    <path d="M22.3606178,41.3735572 L10.0214929,64.6338106 C9.52792793,65.6236086 8.34337194,66.1185076 7.25752896,65.8215682 C4.98712999,65.2276894 1.33474903,63.7429924 0.446332046,60.7735983 C-0.836936937,56.418487 10.3176319,34.7419104 10.3176319,34.7419104 L22.3606178,41.3735572 Z"></path>
                </g>
                <path d="M87.8588867,100.431729 C86.4967927,104.969628 83.6317306,110.927946 81.3908623,114.387881 C81.3019997,114.525086 80.2187991,114.565942 78.1412605,114.510448 C78.7526101,116.165618 79.3422457,117.081567 79.9101673,117.258295 C82.7728443,118.149114 87.6097812,110.626649 90.6698842,100.431729 C93.7299871,90.2368095 93.8287001,81.3286273 90.9660232,80.4378091 C90.2431862,80.2203719 88.9688791,80.9365199 87.1431017,82.5862532 C89.1652467,84.1233512 90.1763192,84.8919002 90.1763192,84.8919002 C90.1763192,88.981574 88.9497816,96.7126616 87.8588867,100.431729 Z" fill="#000000" opacity="0.154735"></path>
                <path d="M53.7512227,56.6826566 L90.1763192,84.8919002 L90.0776062,87.3663952 C89.5840412,96.8684562 86.5239382,106.469497 81.3908623,114.387881 L35.9828829,114.684821 C44.9986701,113.925975 51.7600724,105.922311 56.2670898,90.6738281 C60.7741073,75.425345 59.9354849,64.0949545 53.7512227,56.6826566 Z" fill="#FFFFFF" opacity="0.200711"></path>
                <path d="M29.665251,81.1306677 C24.828314,97.3633552 26.901287,112.309305 34.3047619,114.486861 C40.0301158,116.169518 47.2361647,109.73583 52.4679537,99.2439714 C53.9486486,96.1755976 55.3306306,92.8102843 56.4164736,89.1480316 C57.4036036,85.8816982 58.0945946,82.6153647 58.4894466,79.5469909 C60.1675676,67.471455 57.6997426,57.5734748 51.7769627,55.8908182 C44.4722008,53.6142828 34.5021879,64.99696 29.665251,81.1306677 Z" fill="#000000" opacity="0.152585"></path>
                <path d="M44.7683398,85.6837386 C41.8069498,95.7796784 41.4120978,105.281739 43.3863578,111.616447 C48.5194337,107.360315 53.4550837,99.2439714 56.4164736,89.1480316 C59.3778636,79.0520918 59.7727156,69.5500309 57.7984556,63.2153236 C52.6653797,67.471455 47.7297297,75.5877988 44.7683398,85.6837386 Z" fill="#000000" opacity="0.249678"></path>
                <g transform="translate(40.622394, 78.458213)" fill="var(--color-image-main)">
                    <path d="M3.94851995,-4.61852778e-13 L-1.29674049e-13,13.2632934 L11.7468468,20.8847382 C13.2275418,17.8163643 14.6095238,14.4510511 15.6953668,10.7887984 C16.6824968,7.52246494 17.3734878,4.25613148 17.7683398,1.18775762 L3.94851995,-4.61852778e-13 Z"></path>
                </g>
                <path d="M39.6352638,84.1000617 C38.5494208,87.7623144 39.0429858,91.1276277 40.6223938,91.6225267 C42.3005148,92.1174257 44.4722008,89.5439508 45.5580438,85.8816982 C46.6438867,82.2194455 46.1503218,78.8541322 44.5709138,78.3592332 C42.8927928,77.963314 40.7211068,80.4378091 39.6352638,84.1000617 Z" fill="#FFFFFF" opacity="0.195932"></path>
                <g transform="translate(27.000000, 56.979596)" fill="#FFFFFF">
                    <path d="M59.5239382,25.7347485 C59.5239382,28.6051627 59.2277992,31.475577 58.7342342,34.2470114 C58.2406692,37.0184459 57.6483912,39.8888601 56.7599743,42.5613148 C55.8715573,45.2337694 54.7857143,48.0052039 53.4037323,50.4796989 C52.1204633,53.0531738 50.4423423,55.428689 48.3693694,57.4082851 C50.1462033,55.1317496 51.5281853,52.6572546 52.7127413,50.1827595 C53.8972973,47.6092847 54.8844273,45.0358098 55.7728443,42.3633552 C56.6612613,39.6909005 57.3522523,36.9194661 57.9445302,34.2470114 C58.5368082,31.3765972 59.1290862,28.6051627 59.5239382,25.7347485 Z"></path>
                    <path d="M75.515444,62.3572752 C72.6527671,61.961356 69.8888031,61.2684973 67.1248391,60.4766589 C64.3608752,59.6848205 61.6956242,58.6950225 59.0303732,57.5072649 C61.8930502,57.9031841 64.6570142,58.5960427 67.4209781,59.3878811 C70.1849421,60.1797195 72.8501931,61.1695175 75.515444,62.3572752 Z"></path>
                    <path d="M14.3133848,27.3184253 C14.2146718,28.0112839 14.1159588,28.6051627 14.1159588,29.2980213 C14.1159588,29.5949608 14.2146718,29.99088 14.3133848,30.2878194 C14.4120978,30.5847588 14.6095238,30.8816982 14.7082368,30.7827184 C14.8069498,30.7827184 15.0043758,30.5847588 15.2018018,30.3867992 C15.3992278,30.1888396 15.4979408,29.8919002 15.5966538,29.5949608 C15.7940798,29.0010819 15.9915058,28.4072031 16.1889318,27.8133243 C16.3863578,27.2194455 16.4850708,26.6255667 16.4850708,26.0316879 C16.4850708,25.7347485 16.4850708,25.4378091 16.4850708,25.1408697 C16.4850708,24.8439303 16.3863578,24.6459707 16.2876448,24.5469909 C16.1889318,24.4480111 15.8927928,24.6459707 15.6953668,24.8439303 C15.4979408,25.0418899 15.3005148,25.3388293 15.1030888,25.6357687 C14.7082368,26.0316879 14.4120978,26.6255667 14.3133848,27.3184253 Z M14.3133848,27.3184253 C14.5108108,26.6255667 14.6095238,26.0316879 14.9056628,25.4378091 C15.0043758,25.1408697 15.2018018,24.8439303 15.3992278,24.5469909 C15.4979408,24.4480111 15.5966538,24.2500515 15.7940798,24.1510716 C15.9915058,24.0520918 16.1889318,23.953112 16.4850708,23.953112 C16.7812098,24.0520918 16.9786358,24.2500515 17.0773488,24.3490313 C17.1760618,24.5469909 17.2747748,24.7449505 17.3734878,24.9429101 C17.4722008,25.3388293 17.5709138,25.6357687 17.5709138,26.0316879 C17.5709138,26.7245465 17.5709138,27.4174051 17.3734878,28.1102637 C17.1760618,28.8031223 16.9786358,29.3970011 16.5837838,30.0898598 C16.3863578,30.3867992 16.1889318,30.6837386 15.8927928,30.980678 C15.7940798,31.0796578 15.5966538,31.2776174 15.3992278,31.3765972 C15.2018018,31.475577 15.0043758,31.5745568 14.7082368,31.475577 C14.4120978,31.3765972 14.3133848,31.1786376 14.2146718,30.980678 C14.1159588,30.7827184 14.1159588,30.5847588 14.1159588,30.485779 C14.1159588,30.0898598 14.1159588,29.7929204 14.1159588,29.4959809 C14.0172458,28.6051627 14.1159588,27.9123041 14.3133848,27.3184253 Z"></path>
                    <path d="M23.6911197,0.0989798018 C20.9271557,0.989798018 18.2619048,2.47449505 16.0902188,4.35511128 C15.0043758,5.3449093 14.0172458,6.43368712 13.1288288,7.52246494 C12.2404118,8.71022256 11.5494208,9.99695999 10.8584299,11.2836974 C7.9957529,16.4306471 5.62664093,21.8745362 3.94851995,27.4174051 C2.17168597,33.0592538 0.987129987,38.8990621 1.77683398,44.7388704 C2.17168597,47.6092847 2.86267696,50.4796989 4.44208494,52.954194 C5.23178893,54.1419516 6.21891892,55.2307294 7.6009009,55.8246082 C7.8970399,56.0225678 8.29189189,56.0225678 8.58803089,56.2205274 C8.98288288,56.3195072 9.27902188,56.3195072 9.67387387,56.418487 C10.3648649,56.418487 11.1545689,56.418487 11.8455598,56.1215476 C10.4635779,56.7154265 8.88416988,56.7154265 7.5021879,56.1215476 C5.92277992,55.5276688 4.73822394,54.438891 3.84980695,53.2511334 C2.17168597,50.6776585 1.28326898,47.8072443 0.78970399,44.8378502 C0.296138996,41.8684562 0.394851995,38.8000823 0.78970399,35.8306883 C1.18455598,32.8612942 1.87554698,29.99088 2.76396396,27.1204657 C4.63951094,21.478617 7.00862291,15.9357481 10.0687259,10.8877782 C10.8584299,9.60104078 11.6481338,8.31430335 12.5365508,7.12654573 C13.4249678,5.93878811 14.5108108,4.85001029 15.6953668,3.95919207 C18.0644788,2.07857584 20.8284427,0.692858613 23.6911197,0.0989798018 Z"></path>
                </g>
                <path d="M92.9402831,146.457337 C92.9402831,146.457337 97.1849421,137.054256 96.7900901,134.8767 C96.3952381,132.699144 91.4595882,127.453215 89.1891892,128.344033 C86.9187902,129.234851 90.3737452,135.866498 90.3737452,135.866498 C90.3737452,135.866498 84.6483912,142.795084 85.3393822,146.358357 C86.0303732,149.82265 92.9402831,146.457337 92.9402831,146.457337 Z" fill="#FFE6D5"></path>
                <g transform="translate(-0.000000, 39.128530)" fill="var(--color-image-secondary-2)">
                    <path d="M308.244898,7.52994189 C314.626531,13.6634967 317.034694,23.1644934 311.014286,30.500706 C305.355102,37.3558555 294.277551,38.9193107 287.053061,33.3870847 C269.834694,20.1578489 287.77551,-4.37637041 306.318367,0.674792384 C309.810204,1.63691863 308.846939,6.56781564 305.47551,6.68808142 C300.057143,7.04887876 294.277551,7.16914454 289.822449,11.0176495 C285.126531,15.1066861 285.006122,21.9618356 288.859184,26.7724668 C298.973469,39.1598422 316.553061,20.8794435 305.957143,9.81499172 C304.753061,8.25153657 306.8,6.2070183 308.244898,7.52994189 L308.244898,7.52994189 Z"></path>
                    <path d="M62.4698802,204.169809 C58.1351863,220.405689 53.3188598,236.521304 48.2617169,252.516653 C47.0576353,256.365158 43.4453904,257.567816 40.0739618,255.282766 C26.949472,246.383098 13.9453904,237.723962 1.5433496,227.862168 C-0.864813665,225.937915 -0.262772849,222.209676 2.02498225,220.525955 C16.4739618,210.543895 36.1004925,195.87147 54.5229414,195.87147 C59.2188598,195.87147 62.4698802,202.365822 57.7739618,205.131935 C50.3086557,209.461503 41.3984516,210.904693 33.571921,214.272135 C24.4209006,218.12064 15.3902884,222.570473 7.32294144,228.343231 C7.20253327,225.697384 6.96171695,223.051537 6.84130878,220.405689 C20.6882476,228.94456 33.6923292,238.445557 46.816819,247.946553 C44.1678394,248.427616 41.639268,249.028945 38.9902884,249.510008 C44.649472,233.634925 50.3086557,217.880108 56.5698802,202.245557 C57.7739618,198.878115 63.4331455,200.682101 62.4698802,204.169809 L62.4698802,204.169809 Z"></path>
                </g>
                <g transform="translate(307.000000, 247.000000)" fill="var(--color-image-secondary-1)">
                    <path d="M32.3999164,11.5723625 C23.4897123,18.7883093 13.736651,25.8839904 3.74277349,31.2959505 C0.0101204334,33.3404688 -2.87967549,28.1690402 0.491753086,25.7637246 C9.52236533,19.0288409 19.7570592,13.1358176 29.8713449,8.20492061 C31.6774674,7.24279437 34.326447,9.88864154 32.3999164,11.5723625 L32.3999164,11.5723625 Z" transform="translate(16.035843, 19.882016) rotate(-285.000000) translate(-16.035843, -19.882016) "></path>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {}
</script>

<style lang="scss">

</style>